import React from "react";
import { graphql } from "gatsby";
import GraphQLErrorList from "../components/Blog/graphql-error-list";
import { GatsbyImage } from "gatsby-plugin-image";

import Layout from "../components/Layout";
import SearchEngineOptimization from "../components/SEO";
import ButtonSolid from "../components/Button/ButtonSolid";

export const query = graphql`
  query CPAJobTemplateQuery($id: String!) {
    page: sanityCpaJob(id: { eq: $id }) {
      id
      seoTitle
      metaDescription
      openGraphImage {
        asset {
          url
        }
      }
      twitterCardImage {
        asset {
          url
        }
      }
      city
      state
      heroImage {
        asset {
          gatsbyImageData(layout: CONSTRAINED, width: 1440)
        }
      }
      introText
      citySpecificHeading
      citySpecificText
    }
    blueSquare: file(
      relativePath: { eq: "global/decorative-blue-square.png" }
    ) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED, width: 160)
      }
    }
    blueCrosses: file(
      relativePath: { eq: "global/decorative-blue-crosses.png" }
    ) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED, width: 192)
      }
    }
    team: file(relativePath: { eq: "cpa-jobs/team.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED, width: 1200)
      }
    }
    whoWeAreLookingFor: file(
      relativePath: { eq: "cpa-jobs/what-were-looking-for.jpg" }
    ) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED, width: 540)
      }
    }
    horseHead: file(relativePath: { eq: "global/logo-head-dark-blue.svg" }) {
      publicURL
    }
    rotatingWords: file(
      relativePath: { eq: "global/rotating-words-dark-blue.svg" }
    ) {
      publicURL
    }
  }
`;

const SpecialtiesTemplate = (props) => {
  const { data, errors } = props;
  const page = data && data.page;
  return (
    <Layout>
      {errors && <SearchEngineOptimization title="GraphQL Error" />}
      {page && (
        <SearchEngineOptimization
          title={page.seoTitle}
          description={page.metaDescription}
          openGraphImage={page.openGraphImage && page.openGraphImage.asset.url}
          twitterOpenGraphImage={
            page.twitterCardImage && page.twitterCardImage.asset.url
          }
        />
      )}

      {errors && (
        <div className="container">
          <GraphQLErrorList errors={errors} />
        </div>
      )}

      {page && (
        <>
          <section className="relative mb-20 overflow-hidden py-40 md:mb-32 md:py-72">
            {page.heroImage && (
              <div className="absolute top-0 left-0 h-full w-full">
                <GatsbyImage
                  image={page.heroImage.asset.gatsbyImageData}
                  alt={page.heroHeading}
                  className="h-full w-full"
                />
              </div>
            )}
            <div className="absolute left-0 top-0 h-full w-full bg-black/40"></div>
            <div className="container relative">
              <header className="text-center">
                <h1 className="mb-0 text-white">{page.city} CPA Jobs</h1>
              </header>
            </div>
          </section>

          <section className="mb-20 md:mb-32">
            <div className="container">
              <header className="mx-auto max-w-[720px] text-center">
                <h2>A new way to be a {page.city} CPA</h2>
                <p className="md:text-xl">{page.introText}</p>
                <ButtonSolid
                  href="https://abetterway.cpa/"
                  outboundLink
                  altStyle={3}
                  text={[
                    "Better Way to CPA",
                    <i className="fa-regular fa-up-right-from-square ml-2"></i>,
                  ]}
                />
              </header>
            </div>
          </section>

          <section className="mb-20 md:mb-40">
            <div className="container">
              <GatsbyImage
                image={data.team.childImageSharp.gatsbyImageData}
                className="mb-10"
              />

              <div className="grid gap-y-1.5 md:grid-cols-2 md:gap-x-10 lg:gap-x-20">
                <div>
                  <h2>{page.citySpecificHeading}</h2>
                </div>
                <div>
                  <p>{page.citySpecificText}</p>
                  <ButtonSolid
                    href="https://abetterway.cpa/"
                    outboundLink
                    altStyle={3}
                    text={[
                      "Learn More",
                      <i className="fa-regular fa-up-right-from-square ml-2"></i>,
                    ]}
                  />
                </div>
              </div>
            </div>
          </section>

          <section className="mb-20 md:mb-60">
            <div className="container">
              <div className="grid items-center gap-y-10 md:grid-cols-2 md:gap-x-10 lg:gap-x-20">
                <div className="pl-8">
                  <div className="relative">
                    <div className="absolute -top-4 -left-4 md:-top-8 md:-left-8">
                      <GatsbyImage
                        image={data.blueSquare.childImageSharp.gatsbyImageData}
                      />
                    </div>
                    <div className="relative z-10">
                      <GatsbyImage
                        image={
                          data.whoWeAreLookingFor.childImageSharp
                            .gatsbyImageData
                        }
                        className="z-0 w-full rounded-4xl"
                      />
                      <div className="absolute -left-3 top-3 z-[-10] h-full w-full rounded-3xl bg-primary-500"></div>
                    </div>

                    <div className="absolute -bottom-10 -right-8 md:-bottom-18 md:-right-18">
                      <GatsbyImage
                        image={data.blueCrosses.childImageSharp.gatsbyImageData}
                      />
                    </div>
                  </div>
                </div>
                <div>
                  <h2>What we’re looking for</h2>
                  <p>
                    Will you mesh well with who we are and where we’re headed?
                    We hire integrity, emotional intelligence, personality, and
                    technical capabilities… in that order. And to become a
                    Principal at Dark Horse, you can either graduate from our
                    Principal Accelerator Program or bring a qualifying
                    pre-existing book of business to us.
                  </p>
                </div>
              </div>
            </div>
          </section>

          <div className="container">
            <section className="relative mt-3 mb-20 rounded-3xl bg-primary-50 pt-18 pb-16 md:mb-32 md:pt-24 md:pb-20">
              <div className="absolute left-3 top-3 z-[-10] h-full w-full rounded-3xl bg-primary-500"></div>
              <div className="absolute -left-16 -top-16 flex h-[200px] w-[200px] items-center justify-center">
                <img
                  src={data.rotatingWords.publicURL}
                  alt="Rotating words"
                  className="rotating absolute inset-0"
                />
                <img
                  src={data.horseHead.publicURL}
                  alt="Dark Horse logo"
                  width="75px"
                />
              </div>
              <header className="mx-auto max-w-[817px] text-center">
                <h2>Have Some Questions?</h2>
                <p>
                  “What is the Principal Accelerator Program exactly? What
                  employee benefits do you have? Why would I want to be a Dark
                  Horse Principal when I already have my own book of business?”
                  Inquiring minds want to know, and we have answers to these and
                  many more.
                </p>
                <ButtonSolid
                  href="https://abetterway.cpa/"
                  outboundLink
                  altStyle={3}
                  text={[
                    "Better Way to CPA",
                    <i className="fa-regular fa-up-right-from-square ml-2"></i>,
                  ]}
                />
              </header>
            </section>
          </div>
        </>
      )}
    </Layout>
  );
};

export default SpecialtiesTemplate;
